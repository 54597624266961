import React from "react"
import { AiFillYoutube } from "react-icons/ai"

const VirtualEventBox = ({ className, eventLink = null }) => {
  return (
    <div
      className={`grid max-w-sm bg-white bg-opacity-50 p-4 shadow-xl rounded-md ${className}`}
    >
      <h2 className="text-4xl text-center">
        <span role="img" aria-label="sparkle emoji">
          ✨
        </span>{" "}
        Virtual event{" "}
        <span role="img" aria-label="sparkle emoji">
          ✨
        </span>
      </h2>
      {/* Show youtube icon only if event link is available */}
      {(eventLink && (
        <>
          <p className="mb-0  text-center">
            Follow this link to partipate in the event!
          </p>
          <a
            href={eventLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-center"
            title="Link to event stream"
          >
            <AiFillYoutube className="inline text-youTubeRed text-6xl" />
          </a>
        </>
      )) || (
        <p className="text-lg text-center">
          A link to the event will be posted here as soon as it's available.
        </p>
      )}
    </div>
  )
}

export default VirtualEventBox
