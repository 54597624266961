import React, { useContext } from "react"
import { Link, graphql } from "gatsby"
import BlockContentPrimary from "../components/BlockContentPrimary"
import Img from "gatsby-image"
import FacebookShare from "../components/FacebookShare"
import SEO from "../components/SEO"
import { BiArrowBack } from "react-icons/bi"

import { ConferenceContext } from "../context/conferenceContext"
import VirtualEventBox from "../components/VirtualEventBox"
import Downloads from "../components/Downloads"
import Layout from "../components/layout"
import { PageTitle } from "../components/SiteComponents"
import VideoPlayer from "../components/VideoPlayer"

const H2 = ({ children, className }) => (
  <h2 className={` text-3xl ${className}`}>{children}</h2>
)

const WorkshopTemplate = ({ data, pageContext }) => {
  const workshop = data.sanityWorkshop
  const {
    allFiles,
    presenters: presenterText,
    videoLink,
    linkedEvent,
  } = workshop
  const details = workshop._rawDetails
  const presenters = workshop.presenters2
  const bannerImage = data.sanityWorkshop.bannerImage
    ? data.sanityWorkshop.bannerImage.asset.fluid
    : null
  // GROQ sourcing test - info passed from createPages
  const { groqData } = pageContext

  const { timezone } = useContext(ConferenceContext)

  // files to download
  const uploadData = workshop.uploads
  const { isVirtualEvent, eventLink, title, startTime, endTime } = workshop

  let link = "/workshops"
  let linkText = "All Workshops"

  if (linkedEvent?.slug?.current) {
    link = `/past-events/${linkedEvent.slug.current}`
    linkText = `${linkedEvent.title}`
  }

  return (
    <Layout>
      <SEO title={title} />
      <div className="flex flex-col items-center py-12 bg-gray-300 relative">
        {/* {linkedEvent?.slug?.current && (
          <Link
            className="btn text-center w-auto absolute top-0 left-0 mt-3 md:mt-32 ml-5"
            to={link}
          >
            <BiArrowBack className="-mt-2" />
            &nbsp;{linkText}
          </Link>
        )} */}
        <div className="flex flex-col gap-2 px-8 md:w-3/4 items-center">
          <PageTitle className="text-nofnecGreen mt-20 mb-10">
            {title}
          </PageTitle>
          {workshop.conferenceDate && (
            <H2>Date: {workshop.conferenceDate.date}</H2>
          )}
          {startTime && (
            <H2>
              Time: {startTime}
              {endTime && <span> - {endTime}</span>}{" "}
              {timezone && timezone.toUpperCase()}
            </H2>
          )}
          {/* {workshop.summary && (
            <div className="w-2/3 text-center">
              <H2 className="font-semibold ">Event Summary:</H2>{" "}
              <p>{workshop.summary}</p>
            </div>
          )} */}

          {/* Presenters */}
          <div className="md:max-w-3/4 mx-5">
            {presenterText && (
              <p className="text-xl">
                <span className="italic font-bold">Presented by:</span>
                <br /> {presenterText}
              </p>
            )}
          </div>
          {/* Show section only if it's a virtual event */}
          {isVirtualEvent && (
            <section className="mt-10">
              <VirtualEventBox eventLink={eventLink} />
            </section>
          )}
          {/* TEMPORARILY DISABLED - this is a reference category to presenters (not plain text) */}
          {null && presenters.length > 0 ? (
            <div className="mb-10">
              <h2 className="font-bold text-3xl uppercase tracking-wide">
                Presented by:
              </h2>
              {presenters.map(presenter => {
                const profileImage =
                  presenter.profile && presenter.profile.profilePhoto
                    ? presenter.profile.profilePhoto.asset.fixed
                    : null

                return (
                  <div className="flex justify-center items-middle">
                    {/* <div className="w-16 pr-3">
                    {profileImage && (
                      <Img
                        fixed={profileImage}
                        sizes={{ ...profileImage, aspectRatio: 1 / 1 }}
                      />
                    )}
                  </div> */}
                    <Link
                      className="text-nofnecGreen underline"
                      to={presenter.generalInfo.slug.current}
                    >
                      {presenter.generalInfo.name}
                    </Link>
                    {presenter.organizationName &&
                      `, ${presenter.organizationName}`}
                  </div>
                )
              })}
            </div>
          ) : null}

          {/* Hero Image for event */}
          {bannerImage && (
            <div className="w-full">
              <Img
                fluid={bannerImage}
                sizes={{ ...bannerImage, aspectRatio: 2 / 1 }}
              />
            </div>
          )}
          <div>
            <BlockContentPrimary blockData={details} />
          </div>

          {/* Event Video */}
          {videoLink && (
            <section className="my-20 w-full grid justify-items-center">
              <VideoPlayer url={videoLink} />
            </section>
          )}

          <Downloads files={allFiles} />

          <Link
            className="btn text-center self-center w-auto mt-20 pb-2 pt-3 text-3xl"
            to={link}
          >
            {linkText}
          </Link>

          <FacebookShare
            className="mt-10"
            handle={`/workshops/${workshop.slug.current}`}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query WorkshopQuery($title: String!) {
    sanityWorkshop(title: { eq: $title }) {
      id
      title
      summary
      bannerImage {
        asset {
          fluid(maxWidth: 1500) {
            ...GatsbySanityImageFluid
          }
        }
      }
      presenters
      presenters2 {
        generalInfo {
          name
          slug {
            current
          }
        }
        organizationName
        profile {
          profilePhoto {
            asset {
              fixed(width: 400) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
      }
      startTime(formatString: "h:mm a")
      endTime(formatString: "h:mm a")
      summary
      _rawDetails(resolveReferences: { maxDepth: 10 })
      slug {
        current
      }
      conferenceDate {
        date
      }

      allFiles {
        title
        asset {
          url
          originalFilename
          title
        }
      }
      isVirtualEvent
      eventLink
      videoLink
      linkedEvent {
        isPastEvent
        title
        slug {
          current
        }
      }
    }
  }
`

export default WorkshopTemplate
