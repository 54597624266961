import React from "react"
import { SubTitle } from "./SiteComponents"

const Downloads = ({ files = [], className }) => {
  return (
    <>
      {files.length > 0 && (
        <div>
          <SubTitle className="text-2xl">Downloads:</SubTitle>
          <ol>
            {files.map(file => {
              const fileTitle = file.title || file.asset.originalFilename
              return (
                <li>
                  <a className="link" href={`${file.asset.url}?dl=`}>
                    {fileTitle}
                  </a>
                </li>
              )
            })}
          </ol>
        </div>
      )}
    </>
  )
}

export default Downloads
